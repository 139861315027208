<template functional>
  <div
    v-scroll-lock="true"
    class="loader"
  >
    <div class="loader__spinner" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
